import React from 'react';
import App, { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import withRedux from 'next-redux-wrapper';
import withReduxSaga from 'next-redux-saga';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import getConfig from 'next/config';
import { ToastContainer } from 'react-toastify';
import { getSelectorsByUserAgent } from 'react-device-detect';
import smoothscroll from 'smoothscroll-polyfill';
import * as amplitude from '@amplitude/analytics-browser';

import CONSTANT from '~/utils/constant';
import { cookie, getUserIdFromAccessToken } from '~/utils/common';
import { theme } from '../styles/theme';
import { setHeaders as reduxSetHeaders } from '../redux/modules/http/headers';
import {
  setAppVersion,
  setIsWebview,
  setIsDesktopWeb,
  setIsEnterUseTab,
  setIsEnterByCollect,
  userSummaryInfoRequest,
} from '../redux/modules/store/storeCommon';
import { configureWindow } from '../helpers/window';
import { GlobalStyle } from '../styles/global-styles';

import configureStore from '../redux/store';
import fetcher, { setHeaders as fetcherSetHeaders } from '~/api/lib/fetcher';

import '../styles/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { setUserInfo } from '~/redux/modules/user/userInfo';

const config = getConfig();
const { publicRuntimeConfig } = config;
const { IS_DEV } = publicRuntimeConfig;

// polyfill - scroll smooth
if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame: any) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

interface IApp {
  err: any;
  store: Store;
}

class MyApp extends App<AppProps & IApp> {
  static async getInitialProps({ Component, ctx }) {
    const { req, query, store, pathname } = ctx;
    const isServer = !!req;

    const path = isServer ? req.url : pathname;
    const isStoreFromTab = path.includes('/store') && !path.includes('/crosssell');
    if (isServer) {
      const {
        req: { headers },
        store: { dispatch },
      } = ctx;

      dispatch(reduxSetHeaders({ headers, isServer }));

      if (headers['app-version']) {
        dispatch(setAppVersion(headers['app-version']));
      }

      if (isStoreFromTab && headers[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN]) {
        dispatch(userSummaryInfoRequest(query.washId || undefined));
      }

      const { isDesktop } = getSelectorsByUserAgent(req.headers['user-agent']);
      if (isDesktop) {
        store.dispatch(setIsDesktopWeb(isDesktop));
      }
    }

    if (isStoreFromTab) {
      if (query.enterByCollect === 'Y') {
        store.dispatch(setIsEnterByCollect(true));
      }

      if (query.enterUseTabYn === 'Y') {
        store.dispatch(setIsEnterUseTab(true));
      }
    }

    if (query.webviewYn === 'Y') {
      store.dispatch(setIsWebview(true));
    }

    return {
      pageProps: {
        ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
        pathname: ctx.pathname,
        appVersion: ctx?.req?.headers[CONSTANT.COOKIE.APP_VERSION],
        accessToken: ctx?.req?.headers[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN],
      },
    };
  }

  componentDidMount() {
    if (this.props.pageProps.appVersion !== undefined) {
      cookie.set(CONSTANT.COOKIE.APP_VERSION, this.props.pageProps.appVersion, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365,
      });
    }

    configureWindow(window);
  }

  render() {
    const { Component, pageProps, err, store } = this.props;
    const { accessToken } = pageProps;
    const amplitudeKey = IS_DEV
      ? process.env.NEXT_PUBLIC_AMPLITUDE_KEY_DEV
      : process.env.NEXT_PUBLIC_AMPLITUDE_KEY_PROD;

    if (!amplitudeKey) {
      throw new Error('amplitudeKey is not defined');
    }

    // TODO: 로컬 테스트를 위한 임시 코드 (상용 배포 시 삭제 필요)
    // let userId = 500001367;
    let userId: number | string = 'non_login';

    if (accessToken) {
      if (!fetcher.defaults.headers.common[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN]) {
        fetcherSetHeaders(
          {
            [CONSTANT.HEADERS.HEADER_ACCESS_TOKEN]: accessToken,
          },
          false
        );
      }

      const isServer = typeof window === 'undefined';
      if (!isServer) {
        userId = getUserIdFromAccessToken(accessToken);
      }
    }

    amplitude.init(amplitudeKey, userId.toString(), { autocapture: false });
    store.dispatch(setUserInfo({ userId }));

    return (
      <Provider store={store}>
        <Head>
          <meta
            name="viewport"
            content="initial-scale=1.0,user-scalable=no,maximum-scale=1,width=device-width,viewport-fit=cover"
          />
          <link rel="icon" href="data:," />
        </Head>
        <ThemeProvider theme={theme}>
          <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar
            draggable={false}
            closeButton={false}
            closeOnClick={false}
            pauseOnHover={false}
            newestOnTop={false}
          />
          <GlobalStyle />
          <Component {...pageProps} err={err} />
        </ThemeProvider>
      </Provider>
    );
  }
}

export default withRedux(configureStore)(withReduxSaga(MyApp));
